<template>
  <div>
    <div id="wrapper">
      <app-sidebar />

      <div id="content-wrapper" class="d-flex flex-column">
        <div id="content">
          <top-bar />

          <div class="container-fluid">
            <router-view />
          </div>
        </div>

        <footer class="sticky-footer bg-white">
          <div class="container my-auto">
            <div class="copyright text-center my-auto">
              <span>Copyright &copy; Your Website 2024</span>
            </div>
          </div>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import AppSidebar from '@/components/dashboard/AppSidebar'
import TopBar from '@/components/dashboard/TopBar'

export default {
  components: {
    AppSidebar, TopBar
  },

  mounted() {
    if(this.$store.getters.getAuthUser.name == undefined) {
      return this.$router.push({ name: 'login' })
    } else {
      if(this.$store.getters.getAuthUser.admin == 1) {
        return this.$router.push({ name: 'admin' })
      }
    }
  }
};
</script>
