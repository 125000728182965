<template>
  <div>
    <h3>My Account</h3>
    <ul class="nav title-navs">
      <li class="nav-item">
        <router-link :to="{ name: 'dashboard.my-account' }" class="nav-link"><h6><i class="fal fa-user me-2"></i> My Profile</h6></router-link>
      </li>
      <li class="nav-item">
        <router-link :to="{ name: 'dashboard.my-account.security' }" class="nav-link"><h6><i class="fal fa-lock me-2"></i>Security</h6></router-link>
      </li>
    </ul>

    <div class="card">
      <div class="card-body">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  
}
</script>