<template>
  <div>
    <h1 class="h3 mb-3 text-gray-800">
      <router-link :to="{ name: 'dashboard.projects' }" class="me-3"
        ><i class="fa fa-arrow-left"></i
      ></router-link>
      My Cart
    </h1>
    <div class="row">
      <div class="col-md-9">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th colspan="2">Item</th>
              <th>Available Credits</th>
              <th>Price Per Credit</th>
              <th>Buy Credits</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="cart.length == 0">
              <td colspan="6">There is no data to display</td>
            </tr>
            <tr v-for="(line, i) in cart" :key="`cart-item-${i}`">
              <td width="100px">
                <img :src="line.logo" :alt="line.name" style="width: 100px" />
              </td>
              <td>
                <h6>{{ line.name }}</h6>
                <p class="text-muted mb-1">By {{ line.org }}</p>
                <a href="#" class="text-danger" @click.prevent="remove(i)"
                  ><i class="fa fa-trash me-2"></i>Remove</a
                >
              </td>
              <td>{{ line.available }}</td>
              <td>{{ line.price }}</td>
              <td width="100px">
                <input
                  type="number"
                  min="1"
                  v-model="line.amount"
                  placeholder="Amount Of Credits"
                  class="form-control"
                />
              </td>
              <td>{{ line.amount * line.price }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-md-3" v-if="cart.length > 0">
        <div class="card shadow">
          <div class="card-body">
            <h5>Order Summary</h5>
            <hr />
            <table class="table">
              <tbody>
                <tr>
                  <td>Total Credits</td>
                  <td>{{ total_credits }}</td>
                </tr>
                <tr>
                  <td>Price Per Credit</td>
                  <td>{{ price_per_credit }}</td>
                </tr>
                <tr>
                  <td>Subtotal</td>
                  <td>{{ total }}</td>
                </tr>
                <tr>
                  <td class="fw-bold">Total</td>
                  <td class="fw-bold">{{ total }}</td>
                </tr>
              </tbody>
            </table>
            <div class="mt-3">
              <a href="#" class="btn btn-primary btn-sm w-100" @click.prevent="proceed"
                ><i class="fa fa-money-bill me-2"></i>Proceed To Checkout</a
              >
            </div>
            <hr>
            <div class="mt-4">
              <h6 class="mb-3">Payment Methods</h6>
              <img src="/assets/payments.png" alt="payments" class="w-100">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cart: this.$cart.get(),
    };
  },

  methods: {
    remove(i) {
      this.$cart.removeByKey(i)
      this.cart = this.$cart.get()
    },

    proceed() {
      this.$cart.set(this.cart)

      this.$router.push({ name: 'dashboard.checkout' })
    }
  },

  computed: {
    total_credits() {
      let total = 0

      this.cart.forEach(line => {
        total += parseInt(line.amount)
      })

      return total
    },

    price_per_credit() {
      if(this.cart.length > 0) {
        return this.cart[0].price
      }

      return 0
    },

    total() {
      return this.total_credits * this.price_per_credit
    }
  }
};
</script>
