<template>
  <div class="card">
    <div class="card-body">
      <h4 class="underlined">Thank You For Your Order</h4>
      <p>Order #{{ $route.params.id }} has been placed.</p>
      <p>You will receive an email confirmation shortly.</p>
      <router-link :to="{ name: 'dashboard.orders.show', params: { id: $route.params.id }}" class="btn btn-primary btn-sm"><i class="fa fa-link me-2"></i>View Order</router-link>
    </div>
  </div>
</template>

<script>
export default {
  
}
</script>