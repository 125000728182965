<template>
  <div class="card">
    <div class="card-body" v-if="! loading">
      <h4 class="underlined">Payment</h4>
      <p>
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Iste
        temporibus eos, illum dolores a error minus possimus! Fuga, enim
        eligendi!
      </p>
      <nav>
        <div class="nav nav-tabs" id="nav-tab" role="tablist">
          <button
            class="nav-link active"
            id="nav-home-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-home"
            type="button"
            role="tab"
            aria-controls="nav-home"
            aria-selected="true"
          >
            Pay With Mpesa
          </button>
        </div>
      </nav>
      <div class="tab-content" id="nav-tabContent">
        <div
          class="tab-pane fade show active"
          id="nav-home"
          role="tabpanel"
          aria-labelledby="nav-home-tab"
          tabindex="0"
        >
          <form class="p-3" @submit.prevent="submit">
            <div class="">
              <img src="/assets/mpesa.png" alt="mpesa" style="height: 100px" />
              <p class="text-muted">
                Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                Maiores, cum!
              </p>
            </div>
            <div class="form-floating">
              <input
                type="text"
                class="form-control"
                id="mpesa"
                placeholder="MPESA Phone Number"
                name="mpesa"
                v-model="details.mpesa_number"
              />
              <label for="mpesa">MPESA Phone Number</label>
            </div>
            <div class="mt-3 text-end">
              <button class="btn btn-primary btn-sm">
                <i class="fa fa-save me-2"></i>Complete
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <loading v-if="loading" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      details: {},
      loading: false,
      cart: this.$cart.get(),
      org: JSON.parse(this.$storage.get('cart_org'))
    };
  },

  methods: {
    submit() {
      this.loading = true

      let data = {
        organization_id: this.org.id,
        items: this.cart.map(item => {
          return {
            project_id: item.id,
            amount: item.amount
          }
        })
      }

      this.$axios.post('/api/v1/dashboard/orders', data).then((response) => {
        this.$router.push({ name: 'dashboard.checkout.success', params: { id: response.data.id }})
      })
    }
  }
};
</script>
