<template>
  <div>
    <h1 class="h3 mb-1 text-gray-800 underline">Checkout</h1>
    <ul class="nav title-navs">
      <li class="nav-item">
        <router-link :to="{ name: 'dashboard.checkout' }" class="nav-link"><h6>Organization Information</h6></router-link>
      </li>
      <li class="nav-item">
        <a href="#" class="nav-link"><i class="fa fa-chevron-right"></i></a>
      </li>
      <li class="nav-item">
        <router-link :to="{ name: 'dashboard.checkout.preview' }" class="nav-link"><h6>Preview</h6></router-link>
      </li>
      <li class="nav-item">
        <a href="#" class="nav-link"><i class="fa fa-chevron-right"></i></a>
      </li>
      <li class="nav-item">
        <router-link :to="{ name: 'dashboard.checkout.payment' }" class="nav-link"><h6>Payment</h6></router-link>
      </li>
      <li class="nav-item">
        <a href="#" class="nav-link"><i class="fa fa-chevron-right"></i></a>
      </li>
      <li class="nav-item">
        <router-link :to="{ name: 'dashboard.checkout.success' }" class="nav-link"><h6>Complete</h6></router-link>
      </li>
    </ul>
    <div class="row">
        <div class="col-md-9">
          <router-view />
        </div>
        <div class="col-md-3">
          <div class="card shadow">
            <div class="card-body">
              <h5>Order Summary</h5>
              <hr />
              <table class="table">
                <tbody>
                  <tr>
                    <td>Total Credits</td>
                    <td>{{ total_credits }}</td>
                  </tr>
                  <tr>
                    <td>Price Per Credit</td>
                    <td>{{ price_per_credit }}</td>
                  </tr>
                  <tr>
                    <td>Subtotal</td>
                    <td>{{ total }}</td>
                  </tr>
                  <tr>
                    <td class="fw-bold">Total</td>
                    <td class="fw-bold">{{ total }}</td>
                  </tr>
                </tbody>
              </table>
              <hr />
              <div class="mt-4">
                <h6 class="mb-3">Payment Methods</h6>
                <img src="/assets/payments.png" alt="payments" class="w-100" />
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cart: this.$cart.get(),
    };
  },

  methods: {
    submit() {
      
    }
  },

  computed: {
    total_credits() {
      let total = 0

      this.cart.forEach(line => {
        total += parseInt(line.amount)
      })

      return total
    },

    price_per_credit() {
      if(this.cart.length > 0) {
        return this.cart[0].price
      }

      return 0
    },

    total() {
      return this.total_credits * this.price_per_credit
    }
  }
};
</script>