<template>
  <div class="card">
    <div class="card-body">
      <h5 class="underlined">Organization Information</h5>
      <form @submit.prevent="submit" v-if="! loading">
        <div class="form-group">
          <vue-select :options="orgs" label="name" target="id" v-model="selected_org" placeholder="Select Organization" />
        </div>
        <div class="row" v-if="selected_org">
          <div class="col-md-6 mt-4">
            <div class="form-floating">
                <input
                  type="text"
                  class="form-control"
                  id="registration"
                  placeholder="Registration Number"
                  name="registration"
                  disabled
                  v-model="details.registration_number"
                />
                <label for="registration">Registration Number</label>
              </div>
          </div>
          <div class="col-md-6 mt-4">
            <div class="form-floating">
                <input
                  type="text"
                  class="form-control"
                  id="location"
                  placeholder="Location"
                  name="location"
                  disabled
                  v-model="details.location"
                />
                <label for="location">Location</label>
              </div>
          </div>
        </div>
        <div class="text-end mt-4" v-if="selected_org">
          <button class="btn btn-primary btn-sm"><i class="fa fa-arrow-right me-2"></i>Proceed</button>
        </div>
      </form>
      <loading v-if="loading" />
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch()
  },

  data() {
    return {
      loading: true,
      orgs: [],
      details: {},
      selected_org: null
    }
  },

  methods: {
    fetch() {
      this.loading = true

      this.$axios.get("/api/v1/dashboard/organizations").then((response) => {
        this.orgs = response.data.orgs;
        this.loading = false;
      });
    },

    submit() {
      this.$storage.set('cart_org', JSON.stringify(this.details))
      this.$router.push({ name: 'dashboard.checkout.preview' })
    }
  },

  watch: {
    selected_org() {
      if(this.selected_org) {
        return this.details = this.orgs.filter(org => {
          return org.id == this.selected_org
        })[0]
      }
      
      return null
    }
  }
};
</script>
