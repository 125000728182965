<template>
  <div class="site-content pt-2">
      <div class="bg-white py-2 mb-3">
        <div class="container">
          <h4>Carbon Calculator</h4>
        </div>
      </div>

      <carbon-calculator />
    </div>
</template>

<script>
export default {
  
}
</script>