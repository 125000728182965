<template>
  <div class="card">
    <div class="card-body">
      <router-link :to="{ name: 'dashboard.my-cart' }" class="float-end"><i class="fa fa-pencil-square"></i></router-link>
      <h4 class="underlined">Order Preview</h4>
      <p><strong>Organization:</strong> {{ org.name }}</p>
      <table class="table table-bordered">
        <thead>
          <tr>
            <th colspan="2">Item</th>
            <th>Available Credits</th>
            <th>Price Per Credit</th>
            <th>Buy Credits</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="cart.length == 0">
            <td colspan="6">There is no data to display</td>
          </tr>
          <tr v-for="(line, i) in cart" :key="`cart-item-${i}`">
            <td width="100px">
              <img :src="line.logo" :alt="line.name" style="width: 100px" />
            </td>
            <td>
              <h6>{{ line.name }}</h6>
              <p class="text-muted mb-1">By {{ line.org }}</p>
            </td>
            <td>{{ line.available }}</td>
            <td>{{ line.price }}</td>
            <td>{{ line.amount }}</td>
            <td>{{ line.amount * line.price }}</td>
          </tr>
        </tbody>
      </table>
      <div class="mt-4">
        <router-link :to="{ name: 'dashboard.checkout.payment' }" class="btn btn-primary btn-sm float-end"><i class="fa fa-arrow-right me-2"></i>Proceed</router-link>
        <router-link :to="{ name: 'dashboard.checkout' }" class="btn btn-primary btn-sm"><i class="fa fa-arrow-left me-2"></i>Back</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cart: this.$cart.get(),
      org: JSON.parse(this.$storage.get('cart_org'))
    };
  },
};
</script>
